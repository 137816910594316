import React from 'react';
import {Link} from "gatsby"
import '../scss/global.scss';
import {
    looking__section__cta,
    looking__section__cta__content,
    grid,
} from '../scss-modules/looking-for-work.module.scss';

export default function LookingForWork() {
    return (
        <section id="lookingForWork">
            <div className="container">
                <div className={grid}>

                    <Link to={'/jobs'}>
                        <div className={looking__section__cta}>
                            <img src={'../../ctas/looking-for-work_cta.jpg'} alt={'Looking for work?'}/>
                            <div className={looking__section__cta__content}>
                                <h4>Looking for work?</h4>
                                <span to={'/jobs'} className="button inline arrow-light">
                                    <span style={{color: 'white'}}>View available jobs</span>
                                </span>
                            </div>
                        </div>
                    </Link>

                    <Link to={'/contact-us'}>
                        <div className={looking__section__cta}>
                            <img src={'../../ctas/client-support_cta.jpg'} alt={'Client needing support?'}/>
                            <div className={looking__section__cta__content}>
                                <h4>Client needing support?</h4>
                                <span to={'/contact-us'} className="button inline arrow-light">
                                    <span style={{color: 'white'}}>Contact us</span>
                                </span>
                            </div>
                        </div>
                    </Link>

                    <Link to={'/services/equipment-brokerage'}>
                        <div className={looking__section__cta}>
                            <img src={'../../ctas/equipment-request_cta.jpg'} alt={'Equipment Brokerage required?'}/>
                            <div className={looking__section__cta__content}>
                                <h4>Equipment Required?</h4>
                                <span to={'/services/equipment-brokerage'} className="button inline arrow-light">
                                    <span style={{color: 'white'}}>View details</span>
                                </span>
                            </div>
                        </div>
                    </Link>

                </div>
            </div>
        </section>
    )
}

