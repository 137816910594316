import React from 'react';
import { Helmet } from "react-helmet";
// import {BrowserRouter as Router} from 'react-router-dom';
import Navbar from "../components/header/Navbar";
import HomeSplashBanner from "../components/HomeSplashBanner";
import SectionIntro from "../components/SectionIntro";
import KeySectorsGrid from "../components/KeySectorsGrid";
import IntroVideo from "../components/IntroVideo";
import OurImpact from "../components/OurImpact";
import LookingForWork from "../components/LookingForWork";
import Footer from "../components/Footer";


// markup
const IndexPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Performance Energy | Specialists in mobilizing experience personnel</title>
                <link rel="canonical" href="https://www.performance-energy.com"/>
            </Helmet>
            {/*<Router>*/}
            <Navbar/>
            {/*</Router>*/}

            {/*Hero Banner section*/}
            <HomeSplashBanner
                title={["Redefining the ", <span>Energy</span>, " Sector"]}
                content={"By supporting the reduction of NPT, CAPEX & OPEX expenditure while increasing safety standards, via the mobilisation of highly competent personnel."}
            />

            {/*Our Key Sectors section*/}
            <section id="ourKeySectorsHome">
                <div className="container">
                    <SectionIntro>
                        <h2>Our key sectors</h2>
                        <p>International service quality combined with local compliance.</p>
                    </SectionIntro>
                    <KeySectorsGrid/>
                </div>
            </section>

            {/*Introduction video section*/}
            <div className={'home-intro'}>
                <IntroVideo/>
            </div>

            <OurImpact />

            {/*Looking for work section*/}
            <LookingForWork/>

            {/*Footer*/}
            <Footer/>

        </>
    )
}


export default IndexPage
